// import { merchantProfileInterface } from "services/interface/auth.interface";
import identityService from "../../../services/identityService"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface AsyncFunctionParams {
    rejectWithValue: (value: any) => void;
  }


  
// --------------------------------Get Merchant KYC Status---------------------------------

export const getKYCStatus = createAsyncThunk(
    'kyc/getKYCStatus',
    async (_,params: AsyncFunctionParams) => {
      try {
        const response: any = await identityService.getKYCStatus();
        return response
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return params.rejectWithValue(error.response.data.message)
        } else {
          return params.rejectWithValue(error.message)
        }
      }
    }
  )


  // --------------------------------Get Merchant KYC Status---------------------------------

export const getKYCDetails = createAsyncThunk(
    'kyc/getKYCDetails',
    async (_,params: AsyncFunctionParams) => {
      try {
        const response: any = await identityService.getKYCDetails();
        return response
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return params.rejectWithValue(error.response.data.message)
        } else {
          return params.rejectWithValue(error.message)
        }
      }
    }
  )


    // --------------------------------Get Merchants KYC Details---------------------------------

export const getMerchantKYCDetails = createAsyncThunk(
  'kyc/getMerchantKYCDetails',
  async (data:any,params: AsyncFunctionParams) => {
    try {
      const response: any = await identityService.getMerchantsKYCDetail(data);
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)


    // --------------------------------Get Users KYC Details---------------------------------

    export const getUserKYCDetails = createAsyncThunk(
      'kyc/getUserKYCDetails',
      async (data:any,params: AsyncFunctionParams) => {
        try {
          const response: any = await identityService.getUsersKYCDetail(data);
          return response
        } catch (error: any) {
          if (error.response && error.response.data.message) {
            return params.rejectWithValue(error.response.data.message)
          } else {
            return params.rejectWithValue(error.message)
          }
        }
      }
    )