import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import api from '../../services/identityService';
import { getUser, removeUser } from '../../services/token.service';
import { getKYCStatus } from '../../redux/features/KYC/kycAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';

const LeftMenu = () => {
  const navigate = useNavigate();
  const dispatch:AppDispatch = useDispatch()
  const kycStatus = useSelector((state:any)=>state.kycStatus)
  const [isKycCompleted,setIsKycCompleted] = useState(false)
  const handleLogout = async () => {
    const user = getUser()
    if (user.userType === "Merchant") {
      const res = await api.merchantLogout()
      if (!res?.error) {
        navigate("/")
        navigate(0)
      }
    } else if (user.userType === "Admin") {
      const res = await api.adminLogout()
      if (!res?.error) {
        navigate("/adlg")
        navigate(0)
      }
    }
    else{
      removeUser()
      navigate("/")
      navigate(0)
    }
  }

  useEffect(()=>{
    // dispatch(getKYCStatus())
    if(kycStatus?.data?.kyc_status){
      // setIsKycCompleted(true)
    }
  },[])

  return (
    <>
      <div className={`dbLeft ${ kycStatus?.data?.kyc_status ==="Verified" ? "" : "unverified" }`}>
        <div className="dbLogo">
          <img src="../images/logo.png" alt="" />
        </div>
        <ul className="dbMenu top">
          <li>
            <NavLink to='/merchant/dashboard'>
              <span className="material-icons icon">dashboard</span>Dashboad
            </NavLink>
          </li>
          <li>
          <NavLink to='/merchant/e-kyc'>
              <span className="material-icons icon">credit_score</span>E-KYC
            </NavLink>
          </li>
          <li>
            <NavLink to='/merchant/user-management'>
              <span className="material-icons icon">person</span>User Management
            </NavLink>
          </li>
          <li>
            <NavLink to="/merchant/walletBank">
            <span className="material-icons icon">account_balance_wallet</span>Wallet & Banks
            </NavLink>   
          </li>
          <li>
            <NavLink to='/merchant/transactions'>
              <span className="material-icons icon">sync_alt</span>Transactions
            </NavLink>
          </li>
          <li>
            <a>
              <span className="material-icons icon">schedule_send</span>Requests
            </a>
          </li>
          <li>
            <NavLink to='/merchant/key-management'>
              <span className="material-icons icon">key</span>Key Management
            </NavLink>
          </li>
          <li>
            <NavLink to='/privacy-policy'>
              <span className="material-icons icon">settings</span>Privacy Policy
            </NavLink>
          </li>
        </ul>
        <ul className="dbMenu bot">
          <li>
            <NavLink to='/merchant/settings'>
              <span className="material-icons icon">settings</span>Settings
            </NavLink>
          </li>
          <li onClick={handleLogout}>
            <a>
              <span className="material-icons icon">logout</span>Logout
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default LeftMenu