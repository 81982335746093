
const data: any = localStorage.getItem("user")

export function getLocalRefreshToken() {
    const user = JSON.parse(data);
    return user?.refreshToken;
}

export function getLocalAccessToken() {
    const user = JSON.parse(data);
    return user?.accessToken;
}

export function getUserType() {
    const user = JSON.parse(data);
    return user?.userType;
}

export function updateLocalAccessToken(token: any, userType: string): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            let user = {
                accessToken: token.access_token,
                refreshToken: token.refresh_token,
                userType: userType
            };
            localStorage.setItem("user", JSON.stringify(user));
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

export function getUser() {
    return JSON.parse(data);
}

export function setUser(user: any): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem("user", JSON.stringify(user));
            resolve();
        } catch (error) {
            reject(error);
        }
    });
}

export function removeUser() {
    localStorage.removeItem("user");
}
