import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import profileReducer from './features/profile/profileSlice';
import disputeSlice from './features/dispute/disputeSlice'
import kycDetail from './features/KYC/kycDetailSlice'
import kycStatus from './features/KYC/kycStatusSlice'
import merchantKYCList from "./features/KYC/merchantKYCList"
import userKYCList from "./features/KYC/userKYCList"


export const store = configureStore({
    reducer: {
        auth: authReducer,
        profile: profileReducer,
        dispute: disputeSlice,
        kycDetail: kycDetail,
        kycStatus: kycStatus,
        merchantKYCList: merchantKYCList,
        userKYCList: userKYCList
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
