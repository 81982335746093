import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import api from '../../services/identityService';
import { getUser, removeUser } from '../../services/token.service';

const LeftMenu = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    const user = getUser()
    if (user.userType === "Admin") {
      const res = await api.adminLogout()
      if (!res?.error) {
        navigate("/")
        navigate(0)
      }
    }
    else {
      removeUser()
      navigate("/")
      navigate(0)
    }


  }

  return (
    <>
      <div className="dbLeft">
        <div className="dbLogo">
          <img src="../images/logo.png" alt="logo" />
        </div>
        <ul className="dbMenu top">
          <li>
            <NavLink to='/admin/dashboard'>
              <span className="material-icons icon">dashboard</span>Dashboad
            </NavLink>
          </li>
          <li>
            <NavLink to='/admin/user-management'>
              <span className="material-icons icon">person</span>User Management
            </NavLink>
          </li>
          <li>
            <NavLink to='/admin/merchant-kyc'>
              <span className="material-icons icon">person</span>KYC Management
            </NavLink>
            <NavLink to='/admin/merchant-kyc'>
              <span className="material-icons icon">person</span>Merchant KYC
            </NavLink>
            <NavLink to='/admin/user-kyc'>
              <span className="material-icons icon">person</span>User KYC
            </NavLink>
          </li>
          <li>
            <a>
              <span className="material-icons icon">account_balance_wallet</span>Wallet & Banks
            </a>
          </li>
          <li>
            <NavLink to='/admin/transactions'>
              <span className="material-icons icon">sync_alt</span>Transactions
            </NavLink>
          </li>
          <li>
            <a>
              <span className="material-icons icon">schedule_send</span>Requests
            </a>
          </li>
          <li>
            <NavLink to='/admin/key-management'>
              <span className="material-icons icon">key</span>Key Management
            </NavLink>
          </li>
          <li>
          <NavLink to='/admin/dispute'>
              <span className="material-icons icon">warning</span>Disputes
            </NavLink>
          </li>
          <li>
          <NavLink to='/admin/chat'>
              <span className="material-icons icon">warning</span>Transaction Chats
            </NavLink>
          </li>
          <li>
            <NavLink to='/privacy-policy'>
              <span className="material-icons icon">settings</span>Privacy Policy
            </NavLink>
          </li>
        </ul>
        <ul className="dbMenu bot">
          <li>
            <NavLink to='/admin/help-support'>
              <span className="material-icons icon">help</span>Help &amp; Support
            </NavLink>
          </li>
          <li>
            <NavLink to='/admin/settings'>
              <span className="material-icons icon">settings</span>Settings
            </NavLink>
          </li>

          <li onClick={handleLogout}>
            <a>
              <span className="material-icons icon">logout</span>Logout
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default LeftMenu