import { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { adminLogin } from '../../../redux/features/auth/authAction'
import { Navigate, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ForgotPassword from '../../../component/Admin/AdminForgotPassword';
import ResetPassword from '../../../component/Admin/AdminResetPassword';
import { getLocalAccessToken, getUserType, setUser } from '../../../services/token.service';


interface adminLoginInterface {
  email: string;
  phone_number: string;
  password: string;
  errors: any
}

const initialLoginState: adminLoginInterface = {
  email: "",
  phone_number: "",
  password: "",
  errors: ""
}


const Index = () => {

  // toggling for forget password modal
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // toggling for reset password modal
  const [show2, setShow2] = useState<boolean>(false);
  const handleClose2 = () => setShow2(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false)
  const [loginFormData, setLoginFormData] = useState<adminLoginInterface>(initialLoginState)
  const [errorVisible, setErrorVisible] = useState({ error: false, message: "" })

  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate();

  const { email, password, errors } = loginFormData

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsActive(!isActive);
  };

  const isTokenExist = getLocalAccessToken()
  const userType = getUserType()
  if (isTokenExist && userType === "Admin") {
    return <Navigate to="/admin/dashboard" />
  }else if(isTokenExist && userType === "Merchant"){
    return <Navigate to="/" />
  }

  // add input data into state variable
  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  }

  // handle form validation
  let handleValidation = () => {
    let emailOrPhoneEmpty = "";
    let passwordEmpty = "";
    let formIsValid = true;

    if (!email.trim()) {
      emailOrPhoneEmpty = "Please enter email address.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      emailOrPhoneEmpty = "Email format is not valid.";
      formIsValid = false;
    }

    if (!password.trim()) {
      passwordEmpty = "Please enter password.";
      formIsValid = false;
    }

    setLoginFormData({
      ...loginFormData,
      errors: {
        emailOrPhoneEmpty,
        passwordEmpty,
        formIsValid
      },
    });
    return formIsValid;
  };


  // handle form submit function
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setErrorVisible({ error: false, message: "" })
    const isFormValid = handleValidation()
    if (!isFormValid) {
      return
    }
    setIsLoginLoading(true)
    const data = {
      email,
      phone_number: null,
      password,
    }
    const res: any = await dispatch(adminLogin(data))
    if (res.payload.access_token) {
      const user = {
        accessToken:res.payload.access_token,
        refreshToken:res.payload.refresh_token,
        userType:"Admin"
      } 
      setUser(user)
      navigate("/admin/dashboard")
      navigate(0)
    } else {
      setErrorVisible(res)
    }
    setIsLoginLoading(false)
  }

  return (
    <>
      <div className="d-flex flex-wrap loginCon">
        <div
          className="loginLeft"
          style={{ background: "url(images/login-bg.jpg) no-repeat left bottom" }}
        >
          <div className="loginTop d-flex flex-wrap align-items-center justify-content-center">
            <div className="logininner">
              <div className="loginLogo">
                <img src="./images/logo.png" alt="" />
              </div>
              <h1 className="loginHd">Log in to your account</h1>
              <div className="loginText">
                Welcome back! Please enter your details.
              </div>
              <form onSubmit={handleSubmit} onChange={(e: any) => handleFormChange(e)}>
                <ul className="loginForm p-0 m-0">
                  <li>
                    <input
                      name="email"
                      type="text"
                      placeholder="Enter email or phone"
                      className="formInput"
                    />
                  </li>
                  <span style={{ color: "red" }}>
                    {errors && errors?.emailOrPhoneEmpty}
                  </span>
                  <li>
                    <div className="pr">
                      <input
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        // onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                        className="formInput password"
                      />
                      <span className="material-icons-outlined eye" onClick={togglePasswordVisibility}>
                        {isActive ? 'visibility_off' : 'visibility'}
                      </span>
                    </div>
                    <div className="erroeMessage" style={{ display: errorVisible.error ? "block" : "none" }}>
                      Your email/phone or password is incorrect. Please try again.
                    </div>
                  </li>
                  <span style={{ color: "red" }}>
                    {errors && errors?.passwordEmpty}
                  </span>
                  <li className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="rem">
                      <label className='d-flex align-items-center'><Form.Check className='checkbox me-2 mb-1' /> Remember me</label>
                    </div>
                    <div>
                      <a
                        className="fpLink"
                        onClick={handleShow}>
                        Forgot Password?
                      </a>
                    </div>
                  </li>
                  <li>
                    <button className="loginBtn" disabled={isLoginLoading}>
                      {isLoginLoading ? "Loading..." : "Login"}
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <div
          className="loginBg"
          style={{ background: "url(images/login-img.jpg) no-repeat center top" }}
        />
      </div>

      <Modal show={show}>
        <ForgotPassword
          onClose={handleClose} />
      </Modal>

      {/* <Modal show={show2}>
        <ResetPassword onClose={handleClose2} />
      </Modal> */}
    </>
  )
}

export default Index