import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

interface UpdateProps {
  onClose: () => void;
}

const BankDetail: React.FC<UpdateProps> = ({ onClose }) => {
  const [modalStep, setModalStep] = React.useState<number>(1);

  const handleClose = () => {
    onClose();
  };

  const handleNextStep = () => {
    setModalStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setModalStep((prevStep) => prevStep - 1);
  };
  const handleModalClose = () => {
    onClose();
  };
  const [visible, setVisible] = React.useState(false);


  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="bankDetPopBox">
              <div className="popupHd tac">Bank Details</div>
              <div className="blBox">
                <img src="/images/citiBankCircle.svg" alt="bank logo" />
                <p>Citibank Singapore -5478</p>
              </div>
              <div className="bkDetCont">
                <p>Account Details</p>
                <table>
                  <tr>
                    <td>Account Holder</td>
                    <td>:John Deo</td>
                  </tr>
                  <tr>
                    <td>A/c No.</td>
                    <td>:123456789</td>
                  </tr>
                  <tr>
                    <td>Bank Code</td>
                    <td>:ABC123</td>
                  </tr>
                  <tr>
                    <td>Branch Code</td>
                    <td>:ABC123</td>
                  </tr>
                  <tr>
                    <td>SWIFT/BIC Code</td>
                    <td>:ABC123</td>
                  </tr>
                </table>
              </div>
              <div className="accTogg">
                <div className="accToggInner">
                  <div className="toggTxt">
                    <p className="p1">Default Account</p>
                    <p className="p2">Default Bank Will Receive Money</p>
                  </div>
                  <div className="toggBtn">
                    <Form>
                      <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label=""
                      />
                    </Form>
                  </div>
                </div>
                <div className="btnDelWrp">
                  <button className="delBank" onClick={handleNextStep}>Delete Bank</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {modalStep === 2 && (
          <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="popupHd tac">Delete Bank?</div>
          <div className="tac loginText">Are you sure you want to delete this bank?</div>
          <ul className="popBtnsWrp">           
            <li>
              <Button className="loginBtn block" onClick={() => setVisible(!visible)}>Delete Bank</Button>
            </li>
            <li>
              <Button className="loginBtn block cancel" onClick={handleModalClose}>Cancel</Button>
            </li>
          </ul>
        </div>
        )}

        {modalStep === 3 && (
          <div className="popupBox" style={{ display: visible ? 'block' : 'none' }}>
          <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
          <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
          <div className="popupHd tac">Bank Deleted Successfully!</div>
        </div>
        )}
      </div>
    </>
  );
};

export default BankDetail;
