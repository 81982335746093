import React from 'react'
interface UnblockUserProps {
  onClose: () => void;
}

const UnblockUser: React.FC<UnblockUserProps> = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };
  return (
    <>
      <div>
        <div className="popupBox">
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
          <div className="popupHd tac">User Unblocked Successfully!</div>
        </div>
      </div>
    </>
  )
}

export default UnblockUser