import { Navigate, useLocation } from "react-router-dom"
import { getLocalAccessToken, getUserType } from './services/token.service';
import MerchantHeader from "./component/Merchant/MerchantHeader"
import { useSelector } from "react-redux";
import EKycPage from "./Pages/Merchant/EKYC";
import LeftMenu from "./component/Merchant/MerchantLeftMenu";
import { useState } from "react";

const EkycProtectedRoutes = ({ children }: any) => {
    const kycStatus = useSelector((state: any) => state.kycStatus)
    let location = useLocation();
    console.log("🚀 ~ EkycProtectedRoutes ~ kycStatus:", kycStatus)
    if (kycStatus?.data?.kyc_status === "Verified") {
        return (
            <>
                {children}
            </>
        )
    } else {
        if (location.pathname === "/merchant/e-kyc") {
            return (
                <>
                    <EKycPage />
                </>
            )
        }
        else {
            return <Navigate to="/merchant/e-kyc" state={{ from: location }} replace />
        }
    }
}


const MerchantProtectedRoute = ({ children }: any) => {
    let location = useLocation();
    const accessToken = getLocalAccessToken()
    const userType = getUserType()
    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    if (accessToken && userType === "Merchant") {
        return (
            <>
                <MerchantHeader onClick={toggleMenu} />
                <div className={isActive ? "dbCon active" : "dbCon"}>
                    <LeftMenu />
                    <EkycProtectedRoutes children={children} />
                </div>
            </>
        )
    }
    return <Navigate to="/" state={{ from: location }} replace />

};

export default MerchantProtectedRoute;