import React, { useState } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import { NavLink } from "react-router-dom";


const HelpSupport = () => {

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  return (
    <>
        <div className="dbRight">
        <div className="mainHd">Help & Support</div>
            <div className="flexBox helpSupportBox">
                <div>
                    <div className="supportBox">
                        <ul className="supportLinks flexBox">
                            <li><NavLink to='/admin/help-support/detail'>Account Setup and Access</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Transaction Processing</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Transaction History and Reports</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Account Management</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Integration and Technical Support</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Fees and Charges</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Security and Fraud Prevention</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Payouts and Settlements</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Compliance and Legal</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Miscellaneous</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Future Developments and Updates</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Training and Resources</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Mobile App Integration</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Accessibility and User Interface</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Subscription Management</NavLink></li>
                            <li><NavLink to='/admin/help-support/detail'>Taxation and Invoicing</NavLink></li>
                        </ul>
                    </div>
                </div>
                {/* <div className="right">
                    <span>Still Need Help?</span>
                    <a>Request a Call Back</a>
                </div> */}
            </div>
        </div>
    </>

  )
}

export default HelpSupport