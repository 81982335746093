import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
interface DeleteProps {
  onClose: () => void;
}

const DeleteCard: React.FC<DeleteProps> = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div>
        <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="popupHd tac">Delete Card?</div>
          <div className="tac loginText">Are you sure you want to delete this card?</div>
          <ul className="popBtnsWrp">           
            <li>
              <Button className="loginBtn block" onClick={() => setVisible(!visible)}>Delete</Button>
            </li>
            <li>
              <Button className="loginBtn block cancel" onClick={handleModalClose}>Cancel</Button>
            </li>
          </ul>
        </div>
        <div className="popupBox" style={{ display: visible ? 'block' : 'none' }}>
          <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
          <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
          <div className="popupHd tac">Card Deleted Successfully!</div>
        </div>
      </div>
    </>
  )
}

export default DeleteCard