import React from 'react'

const MessageList = ({ list }: any) => {
    return (
        <>
            {list.map((data: any) => {
                return (
                    <div style={{
                        display: "flex",
                        minWidth: "300px",
                        overflowX: "hidden",
                        justifyContent:data.position === "left" ? "start" : "end" 
                    }} >
                        <div style={{
                            background: "rgb(242 242 242)",
                            borderRadius: "0 5px 5px",
                            margin: "3px 5px 3px 20px",
                            maxWidth: "220px",
                            padding: "6px 9px 8px",
                            position: "relative"
                        }} >
                            <div style={{
                                margin: "0", padding: "0", position: "relative"
                            }} >
                                {/* <div ><span>Emre</span></div> */}
                                <div >{data.text}</div>
                                <div ></div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                )
            })
            }
        </>

    )

}

export default MessageList