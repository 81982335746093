import { createSlice } from "@reduxjs/toolkit"
import {getKYCDetails} from "./kycAction"


interface kycStateinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: null
}

interface PayloadAction {
    payload: any
}

const initialState: kycStateinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: null

}

const kycDetailSlice = createSlice({
    name: 'kycDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getKYCDetails.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(getKYCDetails.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(getKYCDetails.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
    },
})


export default kycDetailSlice.reducer