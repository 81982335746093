import { createSlice } from "@reduxjs/toolkit"
import { getMerchantProfile, setMerchantProfile, getAdminProfile, setAdminProfile } from './profileAction'


interface profileStateinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: null
}

interface PayloadAction {
    payload: any
}

const initialState: profileStateinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: null

}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMerchantProfile.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(getMerchantProfile.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(getMerchantProfile.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
        builder
            .addCase(setMerchantProfile.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(setMerchantProfile.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(setMerchantProfile.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
        builder
            .addCase(getAdminProfile.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(getAdminProfile.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(getAdminProfile.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
        builder
            .addCase(setAdminProfile.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(setAdminProfile.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(setAdminProfile.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })

    },
})

export default profileSlice.reducer