import { createSlice } from "@reduxjs/toolkit"
import { registerUser, adminLogin, merchantLogin } from './authAction'


interface AuthStateinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: null
}

interface PayloadAction {
    payload: any
}

const initialState: AuthStateinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: null

}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // auth user
        builder
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(registerUser.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(registerUser.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
            .addCase(adminLogin.pending, (state, action: PayloadAction) => {
                state.loading = true
                state.message = action.payload
                state.error = false

            })
            .addCase(adminLogin.fulfilled, (state, action: PayloadAction) => {
                state.loading = false
                state.message = action.payload
                state.success = true
                state.error = false

            })
            .addCase(adminLogin.rejected, (state, action: PayloadAction) => {
                state.loading = false
                state.message = action.payload
                state.error = true
                state.data = action?.payload

            }).addCase(merchantLogin.pending, (state, action: PayloadAction) => {
                state.loading = true
                state.message = action.payload
                state.error = false

            })
            .addCase(merchantLogin.fulfilled, (state, action: PayloadAction) => {
                state.loading = false
                state.message = action.payload
                state.success = true
                state.error = false

            })
            .addCase(merchantLogin.rejected, (state, action: PayloadAction) => {
                state.loading = false
                state.message = action.payload.message
                state.error = true
                state.data = action?.payload?.data

            })
    },
})

export default authSlice.reducer