
import disputeInstance from "./instances/dispute.instance"



// ______________________________________get all ticket___________________________________________


async function getAllTickets(data: { skip: number, limit: number }) {
    const { skip, limit } = data
    let response = await disputeInstance.get(`/admin/list/tickets?skip=${skip}&limit=${limit}`);
    try {
      return response.data;
    } catch (err) {
      return err;
    }
  }
  
  // ______________________________________get ticket by id ___________________________________________
  
  
  async function getTicketById(data: { ticketId: number, action: string }) {
    const { ticketId, action } = data
    let response = await disputeInstance.get(`/admin/change/ticket-status/${ticketId}?action=${action}`);
    try {
      return response.data;
    } catch (err) {
      return err;
    }
  }
  
  
  

  
  const disputeService = {
    getAllTickets,
    getTicketById
  };
  
  export default disputeService;