import { createSlice } from "@reduxjs/toolkit"
import { getAllTickets } from "./disputeAction"


interface disputeStateinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: Array<any>
}

interface PayloadAction {
    payload: any
}

const initialState: disputeStateinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: []

}

const disputeSlice = createSlice({
    name: 'dispute',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTickets.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = []
            })
            .addCase(getAllTickets.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(getAllTickets.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = []

            })


    },
})

export default disputeSlice.reducer