import React, { useState } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Merchant/MerchantLeftMenu';
import Receipt from '../../../component/Receipt';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";


const Index = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const [singleDate, setSingleDate] = useState<Date | null>(new Date());
  return (
    <>
      <div className="dbRight">
        <div className="mainHd">Dashboard</div>
        <ul className="flexBox statsList p-0 m-o">
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Total User <span>40,689</span>
                </div>
                <div className="icon" style={{ background: "#E4E4FF" }}>
                  <img src="../images/total-user.png" alt="Total User" />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">8.5%</span> Up from past week
              </div>
            </div>
          </li>
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Total Amount in Wallet <span>$200k</span>
                </div>
                <div className="icon" style={{ background: "#FEF2D6" }}>
                  <img src="../images/wallet.png" alt="Total Amount in Wallet" />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph2.png" alt="" />{" "}
                <span className="number red">2.1%</span> Down from past week
              </div>
            </div>
          </li>
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Total Transactions <span>523k</span>
                </div>
                <div className="icon" style={{ background: "#D9F7E7" }}>
                  <img
                    src="../images/total-transactions.png"
                    alt="Total Transactions"
                  />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">5.4%</span> Up from past week
              </div>
            </div>
          </li>
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Pending Help Queries <span>264</span>
                </div>
                <div className="icon" style={{ background: "#FFDED2" }}>
                  <img src="../images/pending-help.png" alt="Pending Help Queries" />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">3.1%</span> Up from past week
              </div>
            </div>
          </li>
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Donation Received <span>15k</span>
                </div>
                <div className="icon" style={{ background: "#B3F8EE" }}>
                  <img
                    src="../images/donation-received.png"
                    alt="Donation Received"
                  />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">3.2%</span> Up from past week
              </div>
            </div>
          </li>
        </ul>
        <div className="whtBox mt20">
          <div className="flexBox itemCenter justifyContentBetween">
            <div className="dbSubHd">Recent Transactions</div>
            <ul className="flexBox filter p-0">
              <li>
                <div className="filterSearch pr">
                  <input
                    type="text"
                    placeholder="Search by Name, Trx ID"
                    className="filterInput"
                  />{" "}
                  <span className="material-icons icon">search</span>
                </div>
              </li>
              <li>
                <div className="filterDate pr">
                  <DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} />
                  <span className="material-icons icon">calendar_month</span>
                </div>
              </li>
              <li>
                <select className="filterSelect">
                  <option>Status</option>
                </select>
              </li>
            </ul>
          </div>
          <div className="dbTable">
            <table>
              <tbody>
                <tr>
                  <th>Sr. No.</th>
                  <th>From</th>
                  <th>Trx ID.</th>
                  <th>Payment Source</th>
                  <th>Date &amp; Time</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Valentina Meza</td>
                  <td>7c16abcfa14f998355</td>
                  <td>EazyPay Wallet</td>
                  <td>
                    03/20/2024
                    <br /> 10:30 AM
                  </td>
                  <td>$50.00</td>
                  <td>
                    <span className="status success">Success</span>
                  </td>
                  <td>
                    <a className="viewLink" onClick={handleShow}>
                      View Receipt
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Cooper Davies</td>
                  <td>7c16abcfa14f998355</td>
                  <td>EazyPay Wallet</td>
                  <td>
                    03/20/2024
                    <br /> 10:30 AM
                  </td>
                  <td>$50.00</td>
                  <td>
                    <span className="status success">Success</span>
                  </td>
                  <td>
                    <a className="viewLink" onClick={handleShow}>
                      View Receipt
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Aubree Mckay</td>
                  <td>7c16abcfa14f998355</td>
                  <td>Citi Bank Singapore</td>
                  <td>
                    03/20/2024
                    <br /> 10:30 AM
                  </td>
                  <td>$50.00</td>
                  <td>
                    <span className="status failed">Failed</span>
                  </td>
                  <td>
                    <a className="viewLink" onClick={handleShow}>
                      View Receipt
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Anderson Villegas</td>
                  <td>7c16abcfa14f998355</td>
                  <td>EazyPay Wallet</td>
                  <td>
                    03/20/2024
                    <br /> 10:30 AM
                  </td>
                  <td>$50.00</td>
                  <td>
                    <span className="status success">Success</span>
                  </td>
                  <td>
                    <a className="viewLink" onClick={handleShow}>
                      View Receipt
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>London Leblanc</td>
                  <td>7c16abcfa14f998355</td>
                  <td>HSBC</td>
                  <td>
                    03/20/2024
                    <br /> 10:30 AM
                  </td>
                  <td>$50.00</td>
                  <td>
                    <span className="status pending">Pending</span>
                  </td>
                  <td>
                    <a className="viewLink" onClick={handleShow}>
                      View Receipt
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Matthias Keller</td>
                  <td>7c16abcfa14f998355</td>
                  <td>EazyPay Wallet</td>
                  <td>
                    03/20/2024
                    <br /> 10:30 AM
                  </td>
                  <td>$50.00</td>
                  <td>
                    <span className="status failed">Failed</span>
                  </td>
                  <td>
                    <a className="viewLink" onClick={handleShow}>
                      View Receipt
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Iliana Boyd</td>
                  <td>7c16abcfa14f998355</td>
                  <td>Standard Chartered Bank</td>
                  <td>
                    03/20/2024
                    <br /> 10:30 AM
                  </td>
                  <td>$50.00</td>
                  <td>
                    <span className="status success">Success</span>
                  </td>
                  <td>
                    <a className="viewLink" onClick={handleShow}>
                      View Receipt
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flexBox itemCenter justifyContentBetween">
          <div className="total">Showing 1-8 of 20</div>
          <div className="paging">
            <a>
              <span className="material-icons">chevron_left</span>
            </a>
            <a>
              <span className="material-icons">chevron_right</span>
            </a>
          </div>
        </div>
      </div>
      <Modal show={show}>
        <Receipt onClose={handleClose} />
      </Modal>

    </>

  )
}

export default Index