import { useState } from 'react'
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
// import UserDetail from '../../../component/UserDetail';
import BlockUser from '../../../component/BlockUser';
import UnblockUser from '../../../component/UnblockUser';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";


const UserManagement = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [isActive, setIsActive] = useState(false);
  const [singleDate, setSingleDate] = useState<Date | null>(new Date());
  return (
    <>
        <div className="dbRight">
          <div className="mainHd">User Management</div>
          <div className="whtBox">
            <div className="flexBox itemCenter justifyContentBetween">
              <div className="totalNumber">Total: 150</div>
              <ul className="flexBox filter">
                <li>
                  <div className="filterSearch pr"><input type="text" placeholder="Search by Name, Trx ID"
                    className="filterInput" /> <span className="material-icons icon">search</span></div>
                </li>
                <li>
                  <div className="filterDate pr">
                    <DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} />
                    <span className="material-icons icon">calendar_month</span>
                  </div>
                </li>
                <li>
                  <select className="filterSelect">
                    <option>All</option>
                  </select>
                </li>
              </ul>
            </div>
            <div className="dbTable">
              <table>
                <tr>
                  <th>Sr. No.</th>
                  <th>User Name</th>
                  <th>User ID</th>
                  <th>Last Transaction Date</th>
                  <th>Total Transaction Amount</th>
                  <th>Phone Number</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Aryanna Miranda</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$650.50</td>
                  <td className="nowrap">+65 9123 4567</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Giada Knox</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$1260.00</td>
                  <td className="nowrap">+65 8234 5678</td>
                  <td>
                    <div className="flexBox nowrap">
                    <a className="viewLink mr10 unblock" onClick={handleShow3}>Unblock</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Gage Barr</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$1530.50</td>
                  <td className="nowrap">+65 9345 6789</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Bradley Hinton</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$300.00</td>
                  <td className="nowrap">+65 8456 7890</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Haven Randolph</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$259.50</td>
                  <td className="nowrap">+65 7567 8901</td>
                  <td>
                    <div className="flexBox nowrap">
                    <a className="viewLink mr10 unblock" onClick={handleShow3}>Unblock</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Alondra Padilla</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$687.00</td>
                  <td className="nowrap">+65 6789 0123</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Dereon David</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$1120.00</td>
                  <td className="nowrap">+65 7890 1234</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Miles Hickman</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$4850.00</td>
                  <td className="nowrap">+65 8901 2345</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Anahi Cook</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$259.50</td>
                  <td className="nowrap">+65 7567 8901</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10 unblock" onClick={handleShow3}>Unblock</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Delilah Morales</td>
                  <td>123ABC456</td>
                  <td>03/20/2024</td>
                  <td>$4850.00</td>
                  <td className="nowrap">+65 8901 2345</td>
                  <td>
                    <div className="flexBox nowrap">
                      <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                      <a className="viewLink" onClick={handleShow}>View Details</a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="flexBox itemCenter justifyContentBetween">
            <div className="total">Showing 1-10 of 150</div>
            <div className="paging">
              <a><span className="material-icons">chevron_left</span></a>
              <a><span className="material-icons">chevron_right</span></a>
            </div>
          </div>
        </div>
        <Modal show={show}>
          {/* <UserDetail onClose={handleClose} /> */}
        </Modal>
        <Modal show={show2}>
          <BlockUser onClose={handleClose2} />
        </Modal>
        <Modal show={show3}>
          <UnblockUser onClose={handleClose3} />
        </Modal>
    </>

  )
}

export default UserManagement