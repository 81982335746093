import React from 'react'
import Button from 'react-bootstrap/Button';
interface UpdateProps {
  onClose: () => void; 
}

const Update: React.FC<UpdateProps> = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div>
          <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
            <a className="closePopup" onClick={handleModalClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Update Key</div>
                <ul className="loginForm m-0 p-0">
                    <li>
                        <input type="text" placeholder="SecureKeyHub" className="formInput" />
                    </li>
                    <li>
                        <Button className="loginBtn" onClick={() => setVisible(!visible)}>Update</Button>
                    </li>
                </ul>
          </div>
          <div className="popupBox" style={{ display: visible ? 'block' : 'none' }}>
                <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
                <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
                <div className="popupHd tac">Key Updated Successfully!</div>
            </div>
        </div>
    </>
  )
}

export default Update