import { useEffect, useState } from 'react'
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import Receipt from '../../../component/Receipt';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import { getAllTickets } from '../../../redux/features/dispute/disputeAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';





const AdminDispute = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch: AppDispatch = useDispatch()
    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const disputeData = useSelector((state: any) => state.dispute)
    const [singleDate, setSingleDate] = useState<Date | null>(new Date());

    useEffect(() => {
        const getTicketData = async () => {
            const data = {
                skip: 0,
                limit: 10
            }
            const res = await dispatch(getAllTickets(data))
        }

        getTicketData()
    }, [])


    return (
        <>
            <div className="dbRight">
                <div className="mainHd">Disputes</div>
                <div className="whtBox">
                    <div className="flexBox itemCenter justifyContentBetween filterRow">
                        <div className="totalNumber">Total: {disputeData.data.length}</div>
                        <ul className="flexBox filter transactionsFilter">
                            <li>
                                <div className="filterSearch pr"><input type="text" placeholder="Search by Name or ID"
                                    className="filterInput" /> <span className="material-icons icon">search</span></div>
                            </li>
                            <li>
                                <div className="filterDate pr"><DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} /> <span
                                    className="material-icons icon">calendar_month</span></div>
                            </li>
                            {/* <li>
                            <select className="filterSelect">
                                <option>Payment Source</option>
                            </select>
                        </li> */}
                            <li>
                                <select className="filterSelect">
                                    <option>Status</option>
                                </select>
                            </li>
                        </ul>
                    </div>
                    <div className="dbTable">
                        <table>
                            <tr>
                                <th>Sr. No.</th>
                                <th>User</th>
                                <th>Trx ID.</th>
                                <th>Dispute Type</th>
                                <th>Date & Time</th>
                                <th>Amount</th>
                                <th>Dispute Status</th>
                                <th>File</th>
                                <th>Action</th>
                            </tr>
                            {disputeData?.data.map((data: any) => {
                                return (
                                    <tr>
                                        <td>1</td>
                                        <td>Braxton Mosley</td>
                                        <td>{data.ew_transaction_id}</td>
                                        <td>Duplicate Transaction</td>
                                        <td>03/20/2024<br /> 10:30 AM</td>
                                        <td>$50.00</td>
                                        <td><span className="status success">Resolved</span></td>
                                        <td><button className='fileBtn'><img src="/images/fileIcon.svg" alt="file icon" /></button></td>
                                        <td><a className="viewLink">View Receipt</a></td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                <div className="flexBox itemCenter justifyContentBetween">
                    <div className="total">Showing 1-10 of 150</div>
                    <div className="paging">
                        <a href="#"><span className="material-icons">chevron_left</span></a>
                        <a href="#"><span className="material-icons">chevron_right</span></a>
                    </div>
                </div>
            </div>
            <Modal show={show}>
                <Receipt onClose={handleClose} />
            </Modal>
        </>

    )
}

export default AdminDispute;
