import React, { useState } from "react";
// import Header from "../../../component/AdminHeader";
// import LeftMenu from "../../../component/AdminLeftMenu";
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import Receipt from "../../../component/Receipt";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";

const AdminDisputeDetail = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const [singleDate, setSingleDate] = useState<Date | null>(new Date());
  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <LeftMenu />
        <div className="dbRight">
          <div className="mainHd bck">
            <a href="#" className="bckBtn">
              <img src="/images/backbtn.svg" alt="back button" />
            </a>
            Disputes
          </div>
          <div className="whtBox">
            <div className="disDetWrp">
                <div className="dsdBox">
                    <p className="ddBxHd">Dispute Info</p>
                    <div className="dtTbl">
                        <table>
                            <tbody>
                                <tr>
                                    <td><p>Reported By:</p></td>
                                    <td><p>Braxton Mosley</p>   </td>
                                </tr>
                                <tr>
                                    <td><p>Dispute Type:</p> </td>
                                    <td><p>Duplicate Transaction</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Reported On:</p> </td>
                                    <td><p><span>03/20/2024</span> | <span>10:30 AM</span></p> </td>
                                </tr>
                                <tr>
                                    <td><p>Dispute Status:</p> </td>
                                    <td><p>Pending</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Discription:</p> </td>
                                    <td><p><span className="descr">Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Aliquam consectetur a nisl a ultrices. Maecenas mattis</span>
                                    <span className="imgWrp"><img src="/images/fileIcon.svg" alt="fileIcon" /></span></p> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="dsdBox">
                    <p className="ddBxHd">Payment Sender</p>
                    <div className="dtTbl">
                        <table>
                            <tbody>
                                <tr>
                                    <td><p>User:</p></td>
                                    <td><p>Braxton Mosley</p></td>
                                </tr>
                                <tr>
                                    <td><p>Date & Time:</p> </td>
                                    <td><p>03/20/2024  l  10:30 AM</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Payment Source:</p> </td>
                                    <td><p>EazyPay Wallet</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Mobile:</p> </td>
                                    <td><p>(+65) 555-0127</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Trx. ID:</p> </td>
                                    <td><p>7c16abcfa14f998355</p> 
                                    </td>
                                </tr>
                                <tr>
                                    <td><p>Payment Status:</p> </td>
                                    <td><p>Sent</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Amount:</p> </td>
                                    <td><p>$50.00</p> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="dsdBox">
                    <p className="ddBxHd">Payment Receiver</p>
                    <div className="dtTbl">
                        <table>
                            <tbody>
                                <tr>
                                    <td><p>User:</p></td>
                                    <td><p>Swift Mart</p></td>
                                </tr>
                                <tr>
                                    <td><p>Date & Time:</p> </td>
                                    <td><p>03/20/2024  l  10:30 AM</p> </td>
                                </tr>
                                <tr>
                                    <td><p>recieving Source:</p> </td>
                                    <td><p>EazyPay Wallet</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Mobile:</p> </td>
                                    <td><p>(+65) 555-0127</p> </td>
                                </tr>
                                
                                <tr>
                                    <td><p>Payment Status:</p> </td>
                                    <td><p>Received</p> </td>
                                </tr>
                                <tr>
                                    <td><p>Amount:</p> </td>
                                    <td><p>$50.00</p> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="dsdBtnSec">
                    <button className="btnn refund">Refund</button>
                    <button className="btnn reject">Reject</button>
                    <button className="btnn more_inf">More Details</button>
                </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDisputeDetail;
