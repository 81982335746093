import React, { useState } from 'react'
import LeftMenu from '../../../component/Merchant/MerchantLeftMenu';
import Receipt from '../../../component/Receipt';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";


const Transactions = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const [singleDate, setSingleDate] = useState<Date | null>(new Date());
    return (
        <>
            <div className="dbRight">
                <div className="mainHd">Transactions</div>
                <div className="whtBox">
                    <div className="flexBox itemCenter justifyContentBetween">
                        <div className="totalNumber">Total: 500</div>
                        <ul className="flexBox filter transactionsFilter">
                            <li>
                                <div className="filterSearch pr"><input type="text" placeholder="Search by Name, Trx ID"
                                    className="filterInput" /> <span className="material-icons icon">search</span></div>
                            </li>
                            <li>
                                <div className="filterDate pr"><DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} /> <span
                                    className="material-icons icon">calendar_month</span></div>
                            </li>
                            <li>
                                <select className="filterSelect">
                                    <option>Payment Source</option>
                                </select>
                            </li>
                            <li>
                                <select className="filterSelect">
                                    <option>Status</option>
                                </select>
                            </li>
                        </ul>
                    </div>
                    <div className="dbTable">
                        <table>
                            <tr>
                                <th>Sr. No.</th>
                                <th>From</th>
                                <th>Trx ID.</th>
                                <th>Payment Source</th>
                                <th>Date & Time</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Valentina Meza</td>
                                <td>7c16abcfa14f998355</td>
                                <td>EazyPay Wallet</td>
                                <td>03/20/2024<br /> 10:30 AM</td>
                                <td>$50.00</td>
                                <td><span className="status success">Success</span></td>
                                <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Cooper Davies</td>
                                <td>7c16abcfa14f998355</td>
                                <td>EazyPay Wallet</td>
                                <td>03/20/2024<br /> 10:30 AM</td>
                                <td>$50.00</td>
                                <td><span className="status success">Success</span></td>
                                <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Aubree Mckay</td>
                                <td>7c16abcfa14f998355</td>
                                <td>Citi Bank Singapore</td>
                                <td>03/20/2024<br /> 10:30 AM</td>
                                <td>$50.00</td>
                                <td><span className="status failed">Failed</span></td>
                                <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Anderson Villegas</td>
                                <td>7c16abcfa14f998355</td>
                                <td>EazyPay Wallet</td>
                                <td>03/20/2024<br /> 10:30 AM</td>
                                <td>$50.00</td>
                                <td><span className="status success">Success</span></td>
                                <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>London Leblanc</td>
                                <td>7c16abcfa14f998355</td>
                                <td>HSBC</td>
                                <td>03/20/2024<br /> 10:30 AM</td>
                                <td>$50.00</td>
                                <td><span className="status pending">Pending</span></td>
                                <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Matthias Keller</td>
                                <td>7c16abcfa14f998355</td>
                                <td>EazyPay Wallet</td>
                                <td>03/20/2024<br /> 10:30 AM</td>
                                <td>$50.00</td>
                                <td><span className="status failed">Failed</span></td>
                                <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Iliana Boyd</td>
                                <td>7c16abcfa14f998355</td>
                                <td>Standard Chartered Bank</td>
                                <td>03/20/2024<br /> 10:30 AM</td>
                                <td>$50.00</td>
                                <td><span className="status success">Success</span></td>
                                <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="flexBox itemCenter justifyContentBetween">
                    <div className="total">Showing 1-10 of 150</div>
                    <div className="paging">
                        <a><span className="material-icons">chevron_left</span></a>
                        <a><span className="material-icons">chevron_right</span></a>
                    </div>
                </div>

                <Modal show={show}>
                    <Receipt onClose={handleClose} />
                </Modal>


            </div>
        </>

    )
}

export default Transactions