import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import api from '../../services/identityService';

interface ForgotPasswordProps {
    onClose: () => void;
    user_id: string,
    heading?: string
    handleVerify?: any
}

export interface forgetPasswordResInterface {
    user_id: string,
    security_question: string,
    security_quesiton_id: number
}

const EmailAndPhoneVerifyModal: React.FC<ForgotPasswordProps> = ({ onClose, user_id, heading, handleVerify }) => {
    const handleModalClose = () => {
        onClose();
    };
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [emailOtp, setEmailOtp] = useState<string>("")
    const [isErrorInEmailOTP, setIsErrorInEmailOTP] = useState<boolean>(false)
    const [seconds, setSeconds] = useState(30);
    const [isResendOTPLoading, setIsResendOTPLoading] = useState<boolean>(false)
    useEffect(() => {
        if (seconds === 0) return;
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [seconds]);

    useEffect(() => {
        const sendOTP = async () => {
            const res = await api.merchantSendEmailVerificationOTP(user_id)
        }
        sendOTP()
    }, [])


    const handleSubmitEmailVerifyOTP = async () => {
        setSeconds(30)
        setIsLoading(true)
        const data = {
            ew_otp: emailOtp
        }
        const res = await api.merchantEmailVerification(user_id, data)
        if (!res.error) {
            handleVerify(true)
            handleModalClose()
        } else {
            setIsErrorInEmailOTP(true)
        }
        setIsLoading(false)
    }

    const handleMerchantResendOTP = async () => {
        setIsResendOTPLoading(true)
        const res = await api.merchantResendOTP(user_id)
        setIsResendOTPLoading(false)
        setSeconds(30)
    }

    return (
        <>
            <div>
                <div className="popupBox" style={{ display: 'block' }}>
                    <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
                    <div className="popupHd tac">Email Security Verification</div>
                    <div className="tac loginText">Enter the OTP sent to your registered email.</div>
                    <ul className="loginForm">
                        <li>
                            <input type="text" placeholder="Enter OTP" className="formInput" onChange={(e: any) => setEmailOtp(e.target.value)} />
                            <div className="erroeMessage" style={{ display: isErrorInEmailOTP ? "block" : "none" }}>Incorrect OTP.  Please try again.</div>
                        </li>
                        <li>
                            <button disabled={isResendOTPLoading || seconds !== 0} onClick={handleMerchantResendOTP}>{isResendOTPLoading ? "Loading..." : isResendOTPLoading || seconds !== 0 ? `Resend OTP : ${seconds}` : "Resend OTP"} </button>
                        </li>
                        <li>
                            <Button onClick={handleSubmitEmailVerifyOTP} className="loginBtn showPopup">{isLoading ? "Loading..." : "Submit"}</Button>
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )
}

export default EmailAndPhoneVerifyModal