import { merchantProfileInterface } from "services/interface/auth.interface";
import identityService from "../../../services/identityService"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface AsyncFunctionParams {
    rejectWithValue: (value: any) => void;
  }


  
// --------------------------------Get Merchant Profile Details---------------------------------

export const getMerchantProfile = createAsyncThunk(
    'profile/getMerchantProfile',
    async (_,params: AsyncFunctionParams) => {
      try {
        const response: any = await identityService.getMerchantProfileDetails();
        return response
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return params.rejectWithValue(error.response.data.message)
        } else {
          return params.rejectWithValue(error.message)
        }
      }
    }
  )


  // --------------------------------Add Merchant Profile Details---------------------------------

export const setMerchantProfile = createAsyncThunk(
  'profile/setMerchantProfile',
  async (data:merchantProfileInterface,params: AsyncFunctionParams) => {
    try {
      const response: any = await identityService.setMerchantProfileDetails(data);
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)











// --------------------------------Get Admin Profile Details---------------------------------

export const getAdminProfile = createAsyncThunk(
  'profile/getAdminProfile',
  async (_,params: AsyncFunctionParams) => {
    try {
      const response: any = await identityService.getAdminProfileDetails();
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)


// --------------------------------Add Admin Profile Details---------------------------------

export const setAdminProfile = createAsyncThunk(
'profile/setAdminProfile',
async (data:any,params: AsyncFunctionParams) => {
  try {
    const response: any = await identityService.setAdminProfileDetails(data);
    return response
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return params.rejectWithValue(error.response.data.message)
    } else {
      return params.rejectWithValue(error.message)
    }
  }
}
)