import { createSlice } from "@reduxjs/toolkit"
import { getMerchantKYCDetails } from "./kycAction"


interface merchantkycListinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: any
}

interface PayloadAction {
    payload: any
}

const initialState: merchantkycListinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: {}
}

const merchantKYCListSlice = createSlice({
    name: 'kycStatus',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMerchantKYCDetails.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = {}
            })
            .addCase(getMerchantKYCDetails.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = { data: action.payload.data, page: action.payload.page, page_size: action.payload.page_size, total: action.payload.total }

            })
            .addCase(getMerchantKYCDetails.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = {}

            })

    },
})


export default merchantKYCListSlice.reducer