import React, { useEffect, useState } from "react";
// import Header from '../../../component/AdminHeader';
import Header from "component/Admin/AdminHeader";
// import LeftMenu from '../../../component/AdminLeftMenu';
import LeftMenu from "../../../component/Merchant/MerchantLeftMenu";
import Receipt from "../../../component/Receipt";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import identityService from "../../../services/identityService";
import PopUp from "../../../component/PopUp";
import { getKYCDetails, getKYCStatus } from "../../../redux/features/KYC/kycAction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";

const initialState = {
  ew_full_name: "",
  ew_email: "",
  ew_phone_number: "",
  ew_country: "",
  ew_street_Address: "",
  ew_city: "",
  ew_state: "",
  ew_postal_code: "",
  ew_income_tax_id: "",
  errors: ""
}

const EKycPage = () => {
  const [isActive, setIsActive] = useState(false);
  const [stepForm, setStepForm] = useState(1)
  const [firstForm, setFirstForm] = useState<any>(initialState)
  const [isloading, setIsloading] = useState<boolean>(false)
  const [popup, setPopup] = useState({ show: false, message: "" })
  const [documentImagePreview, setDocumentImagePreview] = useState("")
  const [faceImagePreview, setFaceImagePreview] = useState("")
  const [documentFile, setDocumentFile] = useState<File>()
  const [faceImage, setFaceImage] = useState<File>()
  const [idType, setIdType] = useState("")
  const { ew_full_name, ew_email, ew_phone_number, ew_country, ew_street_Address, ew_city, ew_state, ew_postal_code, ew_income_tax_id, errors } = firstForm


  const dispatch: AppDispatch = useDispatch()
  const kycStatus = useSelector((state: any) => state.kycStatus)

  useEffect(() => {
    dispatch(getKYCDetails())
    dispatch(getKYCStatus())
  }, [])

  // handle form validation
  let handleValidation = () => {
    let fullNameEmpty = "";
    let emailEmpty = "";
    let phoneNoEmpty = "";
    let countryEmpty = ""
    let streetAddressEmpty = ""
    let cityEmpty = ""
    let stateEmpty = ""
    let postalCodeEmpty = ""
    let incomeTaxIdEmpty = ""
    let formIsValid = true;

    if (!ew_full_name.trim()) {
      fullNameEmpty = "Please enter full name.";
      formIsValid = false;
    }

    if (!ew_email.trim()) {
      emailEmpty = "Please enter email address.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(ew_email)) {
      emailEmpty = "Email format is not valid.";
      formIsValid = false;
    }

    if (!ew_phone_number.trim()) {
      phoneNoEmpty = "Please enter phone no.";
      formIsValid = false;
    }

    if (!ew_country.trim()) {
      countryEmpty = "Please select country.";
      formIsValid = false;
    }

    if (!ew_street_Address.trim()) {
      streetAddressEmpty = "Please enter street address.";
      formIsValid = false;
    }

    if (!ew_city.trim()) {
      cityEmpty = "Please enter city.";
      formIsValid = false;
    }

    if (!ew_state.trim()) {
      stateEmpty = "Please enter state.";
      formIsValid = false;
    }

    if (!ew_postal_code.trim()) {
      postalCodeEmpty = "Please enter postal code.";
      formIsValid = false;
    }

    if (!ew_income_tax_id.trim()) {
      incomeTaxIdEmpty = "Please enter income tax id.";
      formIsValid = false;
    }

    setFirstForm({
      ...firstForm,
      errors: {
        fullNameEmpty,
        emailEmpty,
        phoneNoEmpty,
        countryEmpty,
        streetAddressEmpty,
        cityEmpty,
        stateEmpty,
        postalCodeEmpty,
        incomeTaxIdEmpty,
        formIsValid,
      },
    });
    return formIsValid;
  };

  const handleChangeFirstForm = (e: any) => {
    const { name, value } = e.target
    setFirstForm({
      ...firstForm,
      [name]: value
    })
  }

  const handleFirstStepFrom = (e: any) => {
    setPopup({ show: false, message: "" })
    e.preventDefault();
    const isFormValid = handleValidation()
    if (!isFormValid) {
      return
    }
    setIsloading(true)
    identityService.addKYC(firstForm).then((res) => {
      setPopup({ show: true, message: "Submit successfully" })
      setIsloading(false)
      setStepForm(2)

    }).catch((err) => {
      setPopup({ show: true, message: err.response.data.message })
      if (err?.response?.data?.message == "Kyc has been already submitted") {
        setStepForm(2)
      }
      setIsloading(false)
    })
  }

  const fileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        const localImagePreview = URL.createObjectURL(file)
        if (e.target.name === "documentImage") {
          setDocumentImagePreview(localImagePreview)
          setDocumentFile(file)
        } else if (e.target.name === "faceImage") {
          setFaceImagePreview(localImagePreview)
          setFaceImage(file)
        }
      } else {
        setPopup({ show: true, message: "Only formats are allowed: jpeg, jpg, png" })
      }
    }
  };

  const handleSecondStepFrom = (e: any) => {
    setPopup({ show: false, message: "" })
    e.preventDefault();

    const secondForm: any = {
      file: documentFile,
      image: faceImage,
      id_type: idType
    }
    setIsloading(true)
    // setStepForm(2)
    identityService.addKYCDocuments(secondForm).then((res) => {
      setPopup({ show: true, message: res.message })
      setIsloading(false)
      window.location.reload()
    }).catch((err) => {
      setPopup({ show: true, message: err.response.data.message })
      setIsloading(false)
      setStepForm(2)
    })
  }

  return (
    <>

      <div className="dbRight">
        <div className="mainHd">E-KYC</div>
        <div className="whtBox">
          <div className="kycOuterWrp">
            {kycStatus.loading ? "Loading..." : kycStatus?.data?.kyc_status ? `Your KYC is Successfully Done (Status : ${kycStatus?.data?.kyc_status})` : stepForm === 1 ?
              // step 1 here starts
              <form onSubmit={handleFirstStepFrom} onChange={handleChangeFirstForm} className="">
                <div className="kyBox">
                  <p className="stps">Step 1 of 2</p>
                  <h3 className="subH">Provide the personal details.</h3>
                  <div className="KYCformWrp">
                    <div className="frmRow">
                      <div className="frmGrp">
                        <span className="fieldHd">
                          First Name <span className="fieldHd star">*</span>
                        </span>
                        <input
                          placeholder="Full name"
                          type="text"
                          className="formInput"
                          name="ew_full_name"
                          defaultValue={ew_full_name}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.fullNameEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">
                          Mobile No. <span className="fieldHd star">*</span>
                        </span>
                        <input
                          placeholder="mob"
                          type="text"
                          className="formInput"
                          name="ew_phone_number"
                          defaultValue={ew_phone_number}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.phoneNoEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">
                          Email id <span className="fieldHd star">*</span>
                        </span>
                        <input
                          placeholder="email"
                          type="text"
                          className="formInput"
                          name="ew_email"
                          defaultValue={ew_email}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.emailEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">Country</span>
                        <select defaultValue={ew_country} name="ew_country" id="" className="formInput">
                          <option value="">Select</option>
                          <option value="india">India</option>
                          <option value="japan">Japan</option>
                        </select>
                        <span style={{ color: "red" }}>
                          {errors && errors?.countryEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">Street Address</span>
                        <input
                          placeholder="street"
                          type="text"
                          className="formInput"
                          name="ew_street_Address"
                          defaultValue={ew_street_Address}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.streetAddressEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">City</span>
                        <input
                          placeholder="city"
                          type="text"
                          className="formInput"
                          name="ew_city"
                          defaultValue={ew_city}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.cityEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">State/Province</span>
                        <input
                          placeholder="state"
                          type="text"
                          className="formInput"
                          name="ew_state"
                          defaultValue={ew_state}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.stateEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">Postal/ZIP Code</span>
                        <input
                          placeholder="Postal"
                          type="text"
                          className="formInput"
                          name="ew_postal_code"
                          defaultValue={ew_postal_code}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.postalCodeEmpty}
                        </span>
                      </div>
                      <div className="frmGrp">
                        <span className="fieldHd">Income Tax Id</span>
                        <input
                          placeholder="tax"
                          type="text"
                          className="formInput"
                          name="ew_income_tax_id"
                          defaultValue={ew_income_tax_id}
                        />
                        <span style={{ color: "red" }}>
                          {errors && errors?.incomeTaxIdEmpty}
                        </span>
                      </div>
                    </div>
                    <div className="btnWrpper">
                      <Button type="submit" className="loginBtn nxtbtn">
                        {isloading ? "Loading..." : "Next"}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
              // step 1 here ends
              : stepForm === 2 ?
                // step 2 here starts
                <form onSubmit={handleSecondStepFrom} >
                  <div className="kyBox">
                    <p className="stps">Step 2 of 2</p>
                    <h3 className="subH">Please upload the document</h3>
                    <div className="KYCformWrp uploadFrmWrp">
                      <div className="frmRow">
                        <div className="frmGrp">
                          <span className="fieldHd">Select Id Proof</span>
                          <select onChange={(e) => setIdType(e.target.value)} defaultValue={idType} name="country" id="" className="formInput">
                            <option value="">Select</option>
                            <option value="Driving license">Driving license</option>
                            <option value="Pan card">Pan card</option>
                          </select>
                        </div>
                        <div className="frmGrp">
                          <span className="fieldHd">Upload File</span>
                          <div className="settingImg uploadWrp">
                            <a className="addIcon">
                              <input
                                onChange={fileUpload}
                                type="file"
                                className="file"
                                name="documentImage"
                              />
                              <img src={documentImagePreview} alt="preview" />
                              {/* <span className="material-icons-outlined">
                              add_a_photo
                            </span> */}
                              <span>+ Upload</span>
                            </a>
                          </div>
                        </div>

                        <div className="frmGrp">
                          <span className="fieldHd">Upload your Image</span>
                          <div className="settingImg uploadWrp">
                            <a className="addIcon">
                              <input
                                onChange={fileUpload}
                                type="file"
                                className="file"
                                name="faceImage"
                              />
                              {/* <span className="material-icons-outlined">
                              add_a_photo
                            </span> */}
                              <img src={faceImagePreview} alt="preview" />

                              <span>+ Upload</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="btnWrpper">
                        <Button type="submit" className="loginBtn nxtbtn">
                          {isloading ? "Loading..." : "Submit"}

                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
                // step 2 here ends
                : ""}

          </div>
        </div>
      </div>
      <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
    </>
  );
};

export default EKycPage;
