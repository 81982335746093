import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import api from '../../services/identityService';


interface ResetPasswordProps {
  onClose: any;
  token: string
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ onClose, token }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleModalClose = () => {
    onClose(false);
  };
  const [visible, setVisible] = React.useState(false);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsActive(!isActive);
  };

  const [password2, setPassword2] = useState('');
  const [showPassword2, setShowPassword2] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [resError, setResError] = useState({ active: false, msg: "" })

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
    setIsActive2(!isActive2);
  };

  const handleResetPassword = async () => {
    setResError({ active: false, msg: "" })
    setIsLoading(true)
    const data = {
      ew_password: password,
      ew_confirm_password: password2
    }
    api.adminResetPassword(data, token).then((res) => {
      // setSecurityData(res.data)
      setIsLoading(false)
      setVisible(!visible)
      // setForgetPasswordModalVisible(false);
      // setQuestionModalVisible(true)
    }).catch((err) => {
      setIsLoading(false)
      setResError({ active: true, msg: err.response.data.message })

    })
  }

  return (
    <>
      <div>
        <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="popupHd tac">Reset Password</div>
          <div className="tac loginText">Create your new password make sure the new password should be different and secure.</div>
          <ul className="loginForm">
            <li>
              <div className="pr">
                <input placeholder="Enter New Password" className="formInput password" type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)} />
                <span className="material-icons-outlined eye" onClick={togglePasswordVisibility}>
                  {isActive ? 'visibility_off' : 'visibility'}
                </span>
              </div>
            </li>
            <li>
              <div className="pr">
                <input placeholder="Enter New Password" className="formInput password" type={showPassword2 ? 'text' : 'password'}
                  onChange={(e) => setPassword2(e.target.value)} />
                <span className="material-icons-outlined eye" onClick={togglePasswordVisibility2}>
                  {isActive2 ? 'visibility_off' : 'visibility'}
                </span>
              </div>
              <div className="erroeMessage" style={{ display: resError.active ? "block" : "none" }}>{resError.msg}</div>
            </li>
            <li>
              <Button className="loginBtn" onClick={handleResetPassword}>{isLoading ? "Loading..." : "Reset Password" }</Button>
            </li>
          </ul>
        </div>
        <div className="popupBox" style={{ display: visible ? 'block' : 'none' }}>
          <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
          <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
          <div className="popupHd tac">Password Reset Successfull!</div>
          <div className="tac loginText">You can now login to your account.</div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
