import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import api from '../../services/identityService';
import { Modal } from 'react-bootstrap';
import ResetPassword from './AdminResetPassword';

interface ForgotPasswordProps {
  onClose: () => void;
}

export interface forgetPasswordResInterface {
  user_id: string,
  security_question: string,
  security_quesiton_id: number
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };
  const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = React.useState(true);
  const [questionVisible, setQuestionModalVisible] = React.useState(false);
  const [otpVisible, setOtpVisible] = useState(false)
  const [forgetPasswordInputData, setForgetPasswordInputData] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [securityData, setSecurityData] = useState<forgetPasswordResInterface>()
  const [securityData, setSecurityData] = useState<any>()
  const [securityAnswer, setSecurityAnswer] = useState<string>("")
  const [userOtp, setUserOtp] = useState<string>("")
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false)
  const [token, setToken] = useState("")
  const [resError, setResError] = useState({ active: false, msg: "" })
  const [seconds, setSeconds] = useState(30);
  const [isResendOTPLoading, setIsResendOTPLoading] = useState<boolean>(false)


  useEffect(() => {
    if (seconds === 0) return;
    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [seconds]);

  const handleForgetPassword = async () => {
    setResError({ active: false, msg: "" })
    setIsLoading(true)
    const data = {
      phone_number: null,
      email: forgetPasswordInputData
    }
    api.adminForgotPassword(data).then((res) => {
      if (res.error) {
        setResError({ active: true, msg: res.message })
        setIsLoading(false)
        return
      }
      setSecurityData(res.data)
      setIsLoading(false)
      setForgetPasswordModalVisible(false);
      setQuestionModalVisible(true)
    }).catch((err) => {
      setIsLoading(false)
      setResError({ active: true, msg: err.response.data.message })
    })
  }

  const handleSubmitAnwser = async () => {
    setResError({ active: false, msg: "" })
    setIsLoading(true)
    const data = {
      user_id: securityData?.user_id,
      security_answer: securityAnswer
    }
    api.adminVerifyAnswer(data).then((res) => {
      setIsLoading(false)
      setOtpVisible(true)
      setQuestionModalVisible(false)
      setOtpVisible(true)
      setSeconds(30)
    }).catch((err) => {
      setIsLoading(false)
      setResError({ active: true, msg: err.response.data.message })
    })
  }

  const handleSubmitOTP = async () => {
    setResError({ active: false, msg: "" })
    setIsLoading(true)
    const data = {
      user_id: securityData?.user_id,
      ew_otp: userOtp
    }
    api.adminVerifyForgotPasswordOTP(data).then((res) => {
      setToken(res.data.token)
      setIsLoading(false)
      setQuestionModalVisible(false)
      // handleModalClose()
      setIsResetPasswordOpen(true)
    }).catch((err) => {
      setIsLoading(false)
      setResError({ active: true, msg: "OTP not verified, Please try again" })
    })
  }

  const handleMerchantResendOTP = async () => {
    setIsResendOTPLoading(true)
    const res = await api.adminResendOTP(securityData?.user_id)
    setIsResendOTPLoading(false)
    setSeconds(30)
  }

  return (
    <>
      <div>
        <div className="popupBox" style={{ display: forgetPasswordModalVisible ? 'block' : 'none' }}>
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="popupHd tac">Forgot Password?</div>
          <div className="tac loginText">
            To retrieve your account enter your email or phone to reset the
            password.
          </div>
          <ul className="loginForm m-0 p-0">
            <li>
              <input
                type="text"
                placeholder="Enter email or phone"
                className="formInput"
                onChange={(e: any) => setForgetPasswordInputData(e.target.value)}
              />
              <div className="erroeMessage" style={{ display: resError.active ? "block" : "none" }}>
                {resError.msg}
              </div>
            </li>
            <li>
              <button className="loginBtn" onClick={handleForgetPassword}>
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </li>
          </ul>
        </div>

        <div className="popupBox" style={{ display: questionVisible ? 'block' : 'none' }}>
          <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
          <div className="popupHd tac">Security Question</div>
          <div className="tac loginText">{securityData?.security_question
          }</div>
          <ul className="loginForm">
            <li>
              <input type="text" placeholder="Enter Answer" className="formInput" onChange={(e: any) => setSecurityAnswer(e.target.value)} />
              <div className="erroeMessage" style={{ display: resError.active ? "block" : "none" }}>Incorrect Answer.  Please try again.</div>
            </li>
            <li>
              <Button onClick={handleSubmitAnwser} className="loginBtn showPopup">{isLoading ? "Loading..." : "Submit"}</Button>
            </li>
          </ul>
        </div>


        <div className="popupBox" style={{ display: otpVisible ? 'block' : 'none' }}>
          <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
          <div className="popupHd tac">Security Verification</div>
          <div className="tac loginText">Enter the OTP sent to your registered email or phone.</div>
          <ul className="loginForm">
            <li>
              <input type="number" placeholder="Enter OTP" className="formInput" onChange={(e: any) => setUserOtp(e.target.value)} />
              <div className="erroeMessage" style={{ display: resError.active ? "block" : "none" }}>Incorrect OTP,  Please try again.</div>
            </li>
            <li>
              <button disabled={isResendOTPLoading || seconds !== 0} onClick={handleMerchantResendOTP}>{isResendOTPLoading ? "Loading..." : isResendOTPLoading || seconds !== 0 ? `Resend OTP : ${seconds}` : "Resend OTP"} </button>
            </li>
            <li>
              <Button onClick={handleSubmitOTP} className="loginBtn showPopup">{isLoading ? "Loading..." : "Submit"}</Button>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={isResetPasswordOpen}>
        <ResetPassword onClose={setIsResetPasswordOpen} token={token} />
      </Modal>
    </>
  )
}

export default ForgotPassword