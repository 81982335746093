import React from 'react';
import Button from 'react-bootstrap/Button';

interface UpdateProps {
  onClose: () => void;
}

const AddCard: React.FC<UpdateProps> = ({ onClose }) => {
  const [modalStep, setModalStep] = React.useState<number>(1);

  const handleClose = () => {
    onClose();
  };

  const handleNextStep = () => {
    setModalStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setModalStep((prevStep) => prevStep - 1);
  };

  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Add Card</div>
            <div className="tac loginText">Please provide the card details</div>
            <ul className="loginForm m-0 p-0">
              <li>
                <input type="text" placeholder="Card Number*" className="formInput" />
              </li>
              <li>
                <input type="text" placeholder="Card Holder Name*" className="formInput" />
              </li>
              <li>
                <input type="text" placeholder="Expiration Date*" className="formInput" />
              </li>
              <li>
                <input type="text" placeholder="CVV*" className="formInput" />
              </li>
             <li>
                <Button className="loginBtn" onClick={handleNextStep}>Add Card</Button>
              </li>
            </ul>
          </div>
        )}

        {modalStep === 2 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>           
            <div className="popupHd tac">Security Verification</div>
            <div className="tac loginText otp">Enter the OTP sent to your registered mobile number with your card.</div>
            <div className="otpWrp">
              <form action="">
                 <div className="otpSec">
                 <input type="text" className="formInput" />
                 <input type="text" className="formInput" />
                 <input type="text" className="formInput" />
                 <input type="text" className="formInput" />
                 </div>
              </form>
            </div>
            <Button className="loginBtn" onClick={handleNextStep}>Verify</Button>
            <p className="dint">
            Didn’t Receive? <a href="#" className="link">Send Again</a> 
            </p>
          </div>
        )}

        {modalStep === 3 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="tac sucessfull">
              <span className="material-icons-outlined">verified</span>
            </div>
            <div className="popupHd tac">Card Added Successfully!</div>
            {/* <Button className="loginBtn" onClick={handleClose}>Close</Button> */}
          </div>
        )}
      </div>
    </>
  );
};

export default AddCard;
