import axios from "axios";
import { getLocalAccessToken, getLocalRefreshToken, getUserType, updateLocalAccessToken } from "../token.service";

const disputeInstance = axios.create({
    baseURL: process.env.REACT_APP_DISPUTE_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
    // withCredentials: true,
});

disputeInstance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            // config.headers["withCredentials"] = true;
            // config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

disputeInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        let val = 0
        if (originalConfig.url !== "/merchants/login" && originalConfig.url !== "/admin/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401) {
                const refreshToken = await getLocalRefreshToken()
                const userType = await getUserType()
                try {
                    const rs = await axios.get(`${process.env.REACT_APP_IDENTITY_BASE_URL}/users/refresh`, {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`
                        }
                    });
                    await updateLocalAccessToken(rs.data, userType);
                    // alert(0)
                    window.location.reload()
                    // return identityInstance(originalConfig);
                    return
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

export default disputeInstance;