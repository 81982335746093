import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import api from '../../services/identityService';

interface ForgotPasswordProps {
    onClose: () => void;
    user_id: string,
    heading?: string
}

export interface forgetPasswordResInterface {
    user_id: string,
    security_question: string,
    security_quesiton_id: number
}

const PhoneVerifyModal: React.FC<ForgotPasswordProps> = ({ onClose, user_id, heading }) => {
    const handleModalClose = () => {
        onClose();
    };
    const navigate = useNavigate();
    const [phoneOtpVisible, setPhoneOtpVisible] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [phoneOtp, setPhoneOtp] = useState<string>("")
    const [isErrorInPhoneOTP, setIsErrorInPhoneOTP] = useState<boolean>(false)
    const [seconds, setSeconds] = useState(30);
    const [isResendOTPLoading, setIsResendOTPLoading] = useState<boolean>(false)
    const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(true)

    useEffect(() => {
        if (seconds === 0) return;
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [seconds]);

    useEffect(() => {
        const sendOTP = async () => {
            const res = await api.merchantSendPhoneVerificationOTP(user_id)
        }
        sendOTP()
    }, [])


    const handleSubmitPhoneVerifyOTP = async () => {
        setIsLoading(true)
        const data = {
            ew_otp: phoneOtp
        }
        const res = await api.merchantPhoneVerification(user_id, data)
        if (!res.error) {
            setIsPhoneModalVisible(false)
        } else {
            setIsErrorInPhoneOTP(true)
        }
        setIsLoading(false)
    }

    const handleMerchantResendOTP = async () => {
        setIsResendOTPLoading(true)
        const res = await api.merchantResendOTP(user_id)
        setIsResendOTPLoading(false)
        setSeconds(30)
    }

    return (
        <>
            <div>
                <div className="popupBox" style={{ display: isPhoneModalVisible ? 'block' : 'none' }}>
                    <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
                    <div className="popupHd tac">Phone Security Verification</div>
                    <div className="tac loginText">Enter the OTP sent to your registered phone.</div>
                    <ul className="loginForm">
                        <li>
                            <input type="text" placeholder="Enter OTP" className="formInput" onChange={(e: any) => setPhoneOtp(e.target.value)} />
                            <div className="erroeMessage" style={{ display: isErrorInPhoneOTP ? "block" : "none" }}>Incorrect OTP.  Please try again.</div>
                        </li>
                        <li>
                            <button disabled={isResendOTPLoading || seconds !== 0} onClick={handleMerchantResendOTP}>{isResendOTPLoading ? "Loading..." : isResendOTPLoading || seconds !== 0 ? `Resend OTP : ${seconds}` : "Resend OTP"} </button>
                        </li>
                        <li>
                            <Button onClick={handleSubmitPhoneVerifyOTP} className="loginBtn showPopup">{isLoading ? "Loading..." : "Submit"}</Button>
                        </li>
                    </ul>
                </div>

                <div className="popupBox" style={{ display: !isPhoneModalVisible ? 'block' : 'none' }}>
                    <a className="closePopup" onClick={() => { handleModalClose(); navigate("/") }}><span className="material-icons">cancel</span></a>
                    <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
                    <div className="popupHd tac">verified Successfull!</div>
                    <div className="tac loginText">You can now login to your account.</div>
                </div>

            </div>
        </>
    )
}

export default PhoneVerifyModal