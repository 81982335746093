"use client"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMerchantProfile } from '../../redux/features/profile/profileAction';
import { AppDispatch } from '../../redux/store';
import { getKYCStatus } from '../../redux/features/KYC/kycAction';
import identityService from '../../services/identityService';
import moment from 'moment-timezone';


const Header = ({ onClick }:any) => {
  const toggleMenu = () => {
    onClick();
  };

  // const [searchActive, setSearchActive] = useState(false);
  const [profileImage, setProfileimage] = useState("")
  const currentTimezone = moment.tz.guess();
  // const showSearch = () => {
  //   setSearchActive(!searchActive);
  // };

  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    dispatch(getMerchantProfile())
    dispatch(getKYCStatus())
    identityService.getMerchantProfileImage().then((res) => {
      if (!res.error) {
        setProfileimage(res.data.profile_picture_path)
      }
    })
  }, [])

  const profileData = useSelector((state: any) => state.profile)
  return (
    <>
      <div className="dbHeader">
        <div className="topLeft flexBox itemCenter">
          <a className="menuToggle" onClick={toggleMenu}>
            <span className="material-icons">menu</span>
          </a>
          {/* <a className="showSearch" onClick={showSearch}>
            <span className="material-icons">search</span>
          </a> */}
          {/* <div className={searchActive ? 'topSearch active' : 'topSearch'}>
            <input type="text" placeholder="Search..." className="input" />
            <button type="button" className="btn">
              <span className="material-icons">search</span>
            </button>
          </div> */}
        </div>
        <div className="topRight flexBox itemCenter">
          <div className="date flexBox itemCenter">
            <span>Today's Date:</span> {moment().tz(currentTimezone).format('DD MMMM YYYY')}&nbsp;
          </div>
          <div className="notification">
            <a>
              <img src="../images/notification.png" alt="" />
              <span className="count">6</span>
            </a>
          </div>
          <div className="account">
            <a className="flexBox itemCenter">
            <img src={ profileImage ? profileImage : "/images/placeholder.png" } style={{
                borderRadius: "50%",
                width: "3rem"
              }} alt="" />
              {profileData.loading ? "Loading..." : `${profileData?.data?.ew_first_name} ${profileData?.data?.ew_last_name}`}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header