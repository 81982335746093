import React, { useState } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Merchant/MerchantLeftMenu';
import Update from '../../../component/Update';
import Delete from '../../../component/Delete';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";


const KeyManagement = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const [singleDate, setSingleDate] = useState<Date | null>(new Date());
    return (
        <>
            <div className="dbRight">
                <div className="mainHd">Key Management</div>
                <div className="newKeyBox">
                    <span className="hd">Create New Key</span>
                    <div className="keyBox"><input type="text" placeholder="Enter Key Name" className="input" /><button type="button" className="btn">Crerate Key</button></div>
                </div>
                <div className="whtBox mt20">
                    <div className="flexBox itemCenter justifyContentBetween">
                        <div className="totalNumber">Total: 150</div>
                        <ul className="flexBox filter end">
                            <li>
                                <div className="filterSearch pr"><input type="text" placeholder="Search by key name"
                                    className="filterInput" /> <span className="material-icons icon">search</span></div>
                            </li>
                            <li>
                                <div className="filterDate pr"><DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} /> <span
                                    className="material-icons icon">calendar_month</span></div>
                            </li>
                        </ul>
                    </div>
                    <div className="dbTable">
                        <table>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Key Name</th>
                                <th>Key</th>
                                <th>Created Date</th>
                                <th>Action</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>SecureKeyHub</td>
                                <td>CipherVault</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>CryptoGuardian</td>
                                <td>KeyForge</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>KeySafePro</td>
                                <td>SecureKeyX</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>CipherVault</td>
                                <td>CryptoKeyGuard</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>KeyMasterX</td>
                                <td>KeyMasterPro</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>SecureLockBox</td>
                                <td>LockBox360</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>EncryptShield</td>
                                <td>CodeGuardian</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>KeyForge360</td>
                                <td>SecurePassLock</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>EncryptShield</td>
                                <td>CodeGuardian</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>KeyForge360</td>
                                <td>SecurePassLock</td>
                                <td>03/20/2024</td>
                                <td>
                                    <div className="flexBox nowrap">
                                        <a className="viewLink unblock mr10" onClick={handleShow2}>Delete</a>
                                        <a className="viewLink" onClick={handleShow}>Update</a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="flexBox itemCenter justifyContentBetween">
                    <div className="total">Showing 1-10 of 150</div>
                    <div className="paging">
                        <a><span className="material-icons">chevron_left</span></a>
                        <a><span className="material-icons">chevron_right</span></a>
                    </div>
                </div>
            </div>
            <Modal show={show}>
                <Update onClose={handleClose} />
            </Modal>
            <Modal show={show2}>
                <Delete onClose={handleClose2} />
            </Modal>
        </>

    )
}

export default KeyManagement