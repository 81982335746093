import identityService from "../../../services/identityService"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { merchantSignupInterface } from "services/interface/auth.interface";


interface AsyncFunctionParams {
  rejectWithValue: (value: any) => void;
}

// --------------------------------Merchant Sign up---------------------------------

export const registerUser = createAsyncThunk(
  'auth/merchantRegister',
  async (data: merchantSignupInterface, params: AsyncFunctionParams) => {
    try {
      const response: any = await identityService.merchantSignup(data);
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)


// --------------------------------Admin Login--------------------------------

export const adminLogin = createAsyncThunk(
  'auth/adminLogin',
  async (data: any, params: AsyncFunctionParams) => {
    try {
      const response: any = await identityService.adminLogin(data);
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)

// --------------------------------Merchant Login--------------------------------

export const merchantLogin = createAsyncThunk(
  'auth/merchantLogin',
  async (data: any, params: AsyncFunctionParams) => {
    try {
      const response: any = await identityService.merchantLogin(data);
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)
