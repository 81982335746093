import './App.scss';
import './assets/css/style.scss';
import MerchantLogin from "./Pages/Merchant/Login"
import AdminLogin from "./Pages/Admin/Login"
import Register from "./Pages/Register"
import PrivacyPolicy from './Pages/PrivacyPolicy';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux"
import { store } from "./redux/store"
import AdminProtectedRoute from './AdminProtectedRoutes';
import MerchantProtectedRoute from './MerchantProtectedRoutes';
import AdminDashboard from './Pages/Admin/Dashboard';
import AdminUserManagement from './Pages/Admin/UserManagement';
import MerchantKyc from './Pages/Admin/MerchantKyc';
import UserKyc from './Pages/Admin/UserKyc';
import AdminTransactions from './Pages/Admin/Transactions';
import AdminKeyManagement from './Pages/Admin/KeyManagement';
import AdminHelpSupport from './Pages/Admin/HelpSupport';
import AdminHelpSupportDeatls from './Pages/Admin/HelpSupport/Detail';
import AdminSettings from "./Pages/Admin/Settings"
import AdminDispute from './Pages/Admin/AdminDispute';
import AdminDisputeDetail from './Pages/Admin/AdminDispute/detailpage';
import AdminChat from "./Pages/Admin/Chat"

import MerchantDashboard from './Pages/Merchant/Dashboard';
import MerchantUserManagement from './Pages/Merchant/UserManagement';
import MerchantTransactions from './Pages/Merchant/Transactions';
import MerchantKeyManagement from './Pages/Merchant/KeyManagement';
import MerchantSettings from "./Pages/Merchant/Settings";
import EKycPage from './Pages/Merchant/EKYC';
import AddCard from './Pages/AddCard';
import WalletBank from './Pages/Merchant/Wallet&Bank/page';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/" element={<MerchantLogin />} />
      <Route path="/adlg" element={<AdminLogin />} />
      <Route path="register" element={<Register />} />
      <Route path="/add-card" element={<AddCard />} />

      {/* Admin Routes */}
      <Route path="/admin">
        <Route path="/admin/dashboard" element={<AdminProtectedRoute><AdminDashboard /></AdminProtectedRoute>} />
        <Route path="/admin/user-management" element={<AdminProtectedRoute><AdminUserManagement /></AdminProtectedRoute>} />
        <Route path="/admin/merchant-kyc" element={<AdminProtectedRoute><MerchantKyc /></AdminProtectedRoute>} />
        <Route path="/admin/user-kyc" element={<AdminProtectedRoute><UserKyc /></AdminProtectedRoute>} />
        <Route path="/admin/transactions" element={<AdminProtectedRoute><AdminTransactions /></AdminProtectedRoute>} />
        <Route path='/admin/key-management' element={<AdminProtectedRoute><AdminKeyManagement /></AdminProtectedRoute>} />
        <Route path='/admin/help-support' element={<AdminProtectedRoute><AdminHelpSupport /></AdminProtectedRoute>} />
        <Route path='/admin/help-support/detail' element={<AdminProtectedRoute><AdminHelpSupportDeatls /></AdminProtectedRoute>} />
        <Route path='/admin/settings' element={<AdminProtectedRoute><AdminSettings /></AdminProtectedRoute>} />
        <Route path='/admin/dispute' element={<AdminProtectedRoute><AdminDispute /></AdminProtectedRoute>} />        
        <Route path='/admin/chat' element={<AdminProtectedRoute><AdminChat /></AdminProtectedRoute>} />        
        <Route path='/admin/dispute-detail' element={<AdminProtectedRoute><AdminDisputeDetail /></AdminProtectedRoute>} />


      </Route>

      {/* Merchant Routes */}
      <Route path="/merchant">
        <Route path="/merchant/dashboard" element={<MerchantProtectedRoute><MerchantDashboard /></MerchantProtectedRoute>} />
        <Route path="/merchant/user-management" element={<MerchantProtectedRoute><MerchantUserManagement /></MerchantProtectedRoute>} />
        <Route path="/merchant/transactions" element={<MerchantProtectedRoute><MerchantTransactions /></MerchantProtectedRoute>} />
        <Route path="/merchant/key-management" element={<MerchantProtectedRoute><MerchantKeyManagement /></MerchantProtectedRoute>} />
        <Route path="/merchant/settings" element={<MerchantProtectedRoute><MerchantSettings /></MerchantProtectedRoute>} />
        <Route path="/merchant/e-kyc" element={<MerchantProtectedRoute><EKycPage /></MerchantProtectedRoute>} />
        <Route path="/merchant/walletBank" element={<MerchantProtectedRoute><WalletBank /></MerchantProtectedRoute>} />
        
      </Route>

      <Route path='privacy-policy' element={<PrivacyPolicy />} />
      {/* ... etc. */}
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </div>
  );
}

export default App;
