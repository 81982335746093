import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
interface ReceiptProps {
  onClose: () => void; 
}

  const Receipt: React.FC<ReceiptProps> = ({ onClose }) => {
    const handleModalClose = () => {
      onClose();
    };
  return (
    <>
      <div>
        <div className="receiptBox">
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="popupHd">Payment Details</div>
          <ul className="paymentDetList p-0 m-0">
            <li className="flexBox itemCenter justifyContentBetween">
              <div className="amount">
                Amount <span>$80</span>
              </div>
              <div>
                <span className="status success">Success</span>
              </div>
            </li>
            <li className="flexBox justifyContentBetween">
              <div className="paidTo">
                <span>Pain To:</span>
                <strong>Swift Mart</strong>
                EazyPay Wallet
                <br />
                (+65) 555-0127
              </div>
              <div>
                <img src="images/user-icon.png" alt="" />
              </div>
            </li>
            <li className="flexBox justifyContentBetween">
              <div>
                <div className="paidTo">
                  <span>Paid From:</span>
                  <strong>Aryanna Miranda</strong>
                  EazyPay Wallet
                  <br />
                  (+65) 987654321
                </div>
                <div className="transaction">
                  Transaction ID: 21365479541236
                  <span>12 Mar, 2024 l 10:30 AMd</span>
                </div>
              </div>
              <div>
                <img src="images/user-img.jpg" alt="" className="roundImg" />
              </div>
            </li>
            <li className="borNone">
              <span className="timelineHd">Timeline</span>
              <ul className="timeline p-0 m-0">
                <li>
                  Payment Started Processing
                  <span>12 Mar, 2024 l 10:30 AM</span>
                </li>
                <li>
                  Merchant Received Payment
                  <span>12 Mar, 2024 l 10:31 AM</span>
                </li>
                <li>
                  Payment Complete
                  <span>You have received a payment of $80</span>
                </li>
              </ul>
            </li>
            <li className="downloadBtns borNone">
              <a className="shareReceipt">
                Share Receipt
              </a>
              <a className="downloadReceipt">
                Download Receipt
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Receipt