import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import api from '../../services/identityService';

const VerifyModal = ({ heading, handleModalClose, handleVerify }: any) => {
    const [registerSuccessfullPopUp, setRegisterSuccessfullPopUp] = useState<boolean>(true)

    return (
        <>
            <div>
                <div className="popupBox" style={{ display: registerSuccessfullPopUp ? 'block' : 'none' }}>
                    <a className="closePopup" onClick={() => handleModalClose(false)}><span className="material-icons">cancel</span></a>
                    <div className="popupHd tac">{heading ? heading : "Registration Successfully"}</div>
                    <div className="tac loginText">Your account has been created successfully, Please verify your Email and Phone for login </div>
                    <ul className="loginForm">
                        <li>
                            <Button onClick={() => {
                                handleVerify(true)
                                handleModalClose(false)
                            }} className="loginBtn showPopup">Verify</Button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default VerifyModal