import React, { useState } from "react";
import Header from "../../../component/Admin/AdminHeader";
import LeftMenu from "../../../component/Merchant/MerchantLeftMenu";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import DeleteCard from "../KeyManagement/DeleteCardModal";
import AddBank from "../KeyManagement/AddBankModal";
import AddCard from "../KeyManagement/AddCardModal";
import BankDetail from "../KeyManagement/BankDetailModal";




const WalletBank = () => {
  // for adding bank 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // for deleting card
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  // for adding card 
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // for bank detail popup
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  
  return (
    <>
      <div className="dbRight">
        <div className="mainHd">Wallet & Banks</div>
        <div className="newKeyBox walletBox">
          <div className="wll_left">
            <div className="wllIcon">
              <img src="/images/wallet_pic.svg" alt="wallet icon" />
            </div>
            <div className="wllText">
              <p className="sbTTL">Total Amount in Wallet</p>
              <p className="wal_amt">$5875.50</p>
            </div>
          </div>
          <div className="wll_right">
            <div className="BTN_wrp">
              <button className="bt">Send Payment</button>
              <button className="bt">Add Money To Wallet</button>
              <button className="bt">Transfer To Bank</button>
            </div>
          </div>
        </div>
        <div className="whtBox mt20">
          <div className="wallDetailWrp">
            <h3 className="sbTtl">Linked Banks</h3>
            <ul className="bankDet">
              <li>
                <div className="bdLeft">
                  <label className="chkCus">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <button onClick={handleShow4}>Citibank Singapore - 4875</button>
                </div>
                <div className="bdRight">
                  <div className="bnkLogo">
                    <img src="/images/citi_logo.png" alt="bankLogo" />
                  </div>
                </div>
              </li>
              <li>
                <div className="bdLeft">
                  <label className="chkCus">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <button onClick={handleShow4}>HSBC Bank Singapore - 2365</button>
                </div>
                <div className="bdRight">
                  <div className="bnkLogo">
                    <img src="/images/hsbc_logo.png" alt="bankLogo" />
                  </div>
                </div>
              </li>
              <li>
                <div className="bdLeft">
                  <button className="addNewBtn" onClick={handleShow}>
                    <img src="/images/addNewBank.svg" alt="addNewBtn" /> + Add
                    New Bank
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="whtBox mt20">
          <div className="wallDetailWrp">
            <h3 className="sbTtl">Linked Cards</h3>
            <ul className="bankDet cardDet">
              <li>
                <div className="bdLeft">
                  <div className="bank_card">
                    <img src="/images/citi_logo.png" alt="bank" />
                  </div>
                  <div className="cardDesc">
                    <p className="crdNumb">Credit Card - 1478 <img src="/images/visa.png" alt="cardType" /></p>
                    <p className="bnk">citi bank</p>
                  </div>

                </div>
                <div className="bdRight">
                  <button className="delBdet" onClick={handleShow2}>
                    <img src="/images/deleteIcon.svg" alt="delete" />
                  </button>
                </div>
              </li>
              <li>
                <div className="bdLeft">
                  <div className="bank_card">
                    <img src="/images/DBS.png" alt="bank" />
                  </div>
                  <div className="cardDesc">
                    <p className="crdNumb">Debit Card - 7853 <img src="/images/visa.png" alt="cardType" /></p>
                    <p className="bnk">DBS bank</p>
                  </div>

                </div>
                <div className="bdRight">
                  <button className="delBdet" onClick={handleShow2}>
                    <img src="/images/deleteIcon.svg" alt="delete" />
                  </button>
                </div>
              </li>
              <li>
                <div className="bdLeft">
                  <button className="addNewBtn" onClick={handleShow3}>
                    <img src="/images/addNewCard.svg" alt="addNewBtn" /> + Add
                    New Card
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>


      {/* Modals to add bank details*/}
      <Modal show={show}>
        <AddBank onClose={handleClose} />
      </Modal>

      {/* Modal for card delete */}
      <Modal show={show2}>
        <DeleteCard onClose={handleClose2} />
      </Modal>

      {/* Modals to add card details*/}
      <Modal show={show3}>
        <AddCard onClose={handleClose3} />
      </Modal>

      {/* Modals to add card details*/}
      <Modal show={show4}>
        <BankDetail onClose={handleClose4} />
      </Modal>
    </>
  );
};

export default WalletBank;
