import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
interface BlockUserProps {
    onClose: () => void;
    message: string
}

const PopUp: React.FC<BlockUserProps> = ({ onClose, message }) => {
    const handleModalClose = () => {
        onClose();
    };
    const [visible, setVisible] = React.useState(false);
    return (
        <>

            <div>
                <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
                    <a className="closePopup" onClick={handleModalClose}>
                        <span className="material-icons">cancel</span>
                    </a>
                    <div className="tac loginText">{message}</div>
                </div>
            </div>

        </>
    )
}

export default PopUp